import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/headerstyle.css';
import '../styles/entire.css';
const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  // products는 배경이 흰 색이므로 header를 처음부터 검정색으로 설정
  const isBlackBackgroundPage = location.pathname === '/products' || location.pathname === '/other-page'; // Add your routes here

  useEffect(() => {
    //스크롤하는 순간 header 배경 색상을 검정으로 변경하기 위해 IsScrolled 변수 세팅
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`header ${isScrolled || isBlackBackgroundPage ? 'scrolled' : ''}`}>
      <Link className="logo" to="/"></Link>
      <nav className="nav">
        <Link
          className="nav-item"
          to="/products"
          style={{ color: location.pathname === '/products' ? '#21ea15' : 'white' }}
        >
          {windowWidth <= 505 ? 'Prod' : 'Products'}
        </Link>
        <Link className="nav-item" to="/about" style={{ color: location.pathname === '/about' ? '#21ea15' : 'white' }}>
          {windowWidth <= 505 ? 'About' : 'About Us'}
        </Link>
        <Link
          className="nav-item"
          to="/contact"
          style={{ color: location.pathname === '/contact' ? '#21ea15' : 'white' }}
        >
          {windowWidth <= 505 ? 'Contact' : 'Contact Us'}
        </Link>
      </nav>
    </div>
  );
};

export default Header;
