import React, { useState, useEffect } from 'react';
import '../styles/contactstyle.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/entire.css';
const Contact = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="outer">
      <div className="contact-content">
        <Header />
        {/* contact BG */}
        <div className="contact-bg"></div>

        <div className="contact-page">
          {/* contact Upper Box */}
          <div className="contact-header">
            <div className="contact-title">CONTACT US</div>
            <div className="contact-main">
              {windowWidth > 840 ? (
                <span>
                  궁금한 점이 있으신가요? <br />
                  Labinno 게시판에 문의 사항을 작성해 주시면 신속하게 답변해 드리겠습니다.
                  <br />
                </span>
              ) : (
                <>
                  <span>
                    궁금한 점이 있으신가요? <br />
                    Labinno 게시판에 문의 사항을 작성해 주시면 <br />
                    신속하게 답변해 드리겠습니다.
                    <br />
                  </span>
                </>
              )}

              <a href="https://groups.google.com/g/labinno" className="submit-button" target="_blank">
                Labinno 게시판 바로가기
              </a>
            </div>
          </div>
          <div className="divider-container">
            <hr className="divider" />
          </div>
          {/* contact Lower Box */}
          <div className="contact-body">
            <div className="map-container">
              <iframe
                className="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.7488843217516!2d127.06063997652956!3d37.56097972444554!2m3!1f0!2f0!3f0!3m3!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca5dc78ffe92b%3A0x2659597771dc00c3!2z656Y67mE64W4!5e0!3m2!1sko!2skr!4v1723608152727!5m2!1sko!2skr"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact-info">
              <div className="contact-info-title">오시는 길</div>
              <br />
              {windowWidth > 1070 ? (
                <span>서울특별시 성동구 자동차시장 1길 49 화성빌딩 3층 (04808)</span>
              ) : (
                <>
                  <span>서울특별시 성동구 자동차시장 1길 49</span>
                  <br />
                  <span>화성빌딩 3층 (04808)</span>
                </>
              )}
              <div className="contact-tel">
                TEL. +82 2 2282 0301
                <br />
                FAX. +82 2 2282 0301
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
