import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/footerstyle.css';
import '../styles/entire.css';
const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className="footer">
      <div className="footer-left">
        <div className="footer-logo"></div>
        <div className="footer-address">
          <div className="footer-line">
            <span className="footer-title">주소 </span>
            <div className="footer-content">
              {windowWidth > 500 ? (
                <span>서울특별시 성동구 자동차시장 1길 49 화성빌딩 3층 (04808)</span>
              ) : (
                <>
                  <span>서울특별시 성동구 자동차시장 1길 49</span>
                  <br />
                  <span>화성빌딩 3층 (04808)</span>
                </>
              )}
            </div>
          </div>
          <div className="footer-line">
            <span className="footer-title">TEL. </span>
            <span className="footer-content">+82 2 2282 0301</span>
          </div>
          <div className="footer-line">
            <span className="footer-title">FAX. </span>
            <span className="footer-content">+82 2 2282 0301</span>
          </div>
          {/* <div className="footer-line">
            <span className="footer-title">Email. </span>
            <span className="footer-content">info@example.com</span>
          </div> */}
        </div>
        {/* <div className="footer-app">
          <p className="footer-app-title">ShoTrack 어플 설치</p>
          <div className="footer-app-icon"></div>
        </div> */}
      </div>
      <div className="footer-copyright" style={{ width: windowWidth }}>
        Copyright © <span className="green">2024 Labinno</span> . All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
