import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/productsstyle.css';
import '../styles/entire.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Inquery from '../components/Inquery';

const ProductPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleScroll = () => {
      const elementsToAnimate = document.querySelectorAll(
        '.product-contact, .prod-image,.prod-title,.prod-description'
      );
      const windowHeight = window.innerHeight;

      elementsToAnimate.forEach((element) => {
        if (element.getBoundingClientRect().top < windowHeight - 200) {
          element.classList.add('visible');
        }
      });
    };
    // 화면 너비 조절
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      if (window.innerWidth >= 1024) {
        // 여기에서 너비 조건 설정
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    }; // Cleanup the event listener on component unmount
  }, []);
  return (
    <div className="products-wrapper">
      <Header />
      <div className="products-content" style={{ backgroundColor: 'whitesmoke' }}>
        {/* First Box (products BG) */}
        <div className="produc-topBox">
          <div className="shotrackLogo"></div>
          <span className="produc-topMent">
            프로, 아마추어 모두를 위한 AI 기반 샷 분석 초고속 <br />
            스테레오 비전 시스템으로 실시간 3D 좌표 측정
          </span>
        </div>

        <div className="button-card-wrapper">
          {/* Four Buttons */}
          {/* <div className="button-container">
            <button className="custom-button blue">
              <span role="img" aria-label="wave">
                🧠
              </span>{' '}
              Ai기반
            </button>
            <button className="custom-button green">
              <span role="img" aria-label="handshake">
                🎞️
              </span>{' '}
              스윙 모습 플레이
            </button>
            <button className="custom-button yellow">
              <span role="img" aria-label="talk">
                📱
              </span>{' '}
              다양한 디바이스
            </button>
            <button className="custom-button red">
              <span role="img" aria-label="search">
                ⛳
              </span>{' '}
              다중궤적
            </button>
          </div> */}
          {/* Four Cards */}
          <div className="prod-container">
            {/* Card 1 */}
            <div className="prod-card " id="card-Ai">
              <div className="prod-border">
                <div className="prod-image " id="cardimg-Ai"></div>
                <div className="prod-cardText">
                  <h2 className="prod-title">Ai</h2>
                  <p className="prod-description">
                    스윙 당시의 골프공만 보고 전체를 예측하는 론치 모니터와 달리, 10~100m까지 관측된 실측 정보를
                    기반으로 공의 전체 궤적 정보 도출. 인도어에서도 스크린의 정확성을!
                  </p>
                </div>
              </div>
            </div>
            {/* Card 2 */}
            <div className="prod-card" id="card-Swing">
              <div className="prod-border">
                <div className="prod-image" id="cardimg-Swing"></div>
                <div className="prod-cardText">
                  <h2 className="prod-title">Swing</h2>
                  <p className="prod-description">
                    국내 최대 니즈, 스윙 모습 플레이. 공의 궤적과 스윙 모습을 함께 띄워 자세 교정에 용이
                  </p>
                </div>
              </div>
            </div>
            {/* Card 3 */}
            <div className="prod-card " id="card-Device">
              <div className="prod-border">
                <div className="prod-image" id="cardimg-Device"></div>
                <div className="prod-cardText">
                  <h2 className="prod-title">Device</h2>
                  <p className="prod-description">키오스크, 태블릿, 심지어 개인 스마트폰으로도 궤적 정보 확인 가능</p>
                </div>
              </div>
            </div>
            {/* Card 4 */}
            <div className="prod-card" id="card-Multiple">
              <div className="prod-border">
                <div className="prod-image" id="cardimg-Multiple"></div>
                <div className="prod-cardText">
                  <h2 className="prod-title">Multiple</h2>
                  <p className="prod-description">
                    카메라 2대로 20~30타석의 궤적 동시 추적 가능! 가성비, 설치 과정 단순화
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Inquery Box */}
        <Inquery />
      </div>
      <Footer />
    </div>
  );
};

export default ProductPage;
