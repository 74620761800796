import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/homestyle.css';
import Wrap from '../Functions/Wrapper';
import ScrollToTopButton from '../Functions/ScrollToTopButton';
import Inquery from '../components/Inquery';
const Home = () => {
  // second box의 숫자들 관리
  const [contractCount, setContractCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [recordingHours, setRecordingHours] = useState(0);
  const [salesCount, setSalesCount] = useState(0);
  // 윈도우 크기 상태 관리
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // 기준 날짜를 설정합니다.
    const startDate = new Date('2024-03-16');

    // 날짜 차이를 계산하는 함수
    const calculateDaysDifference = () => {
      const currentDate = new Date(); // 현재 날짜
      const timeDiff = currentDate - startDate; // 밀리초 단위로 차이 계산
      const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // 일수로 변환
      return dayDiff;
    };

    //매개변수로 시작점, 변경을 원하는 요소, 목표 숫자를 받아서 현재 값이 목표보다 작으면 1씩 더하고 목표값이 되면 목표값 표시
    const handleCountUp = (value, setter, target) => {
      const interval = setInterval(() => {
        setter((prev) => {
          if (prev < target) {
            return prev + 1; // 이 부분은 증가 속도나 증가량을 조절하여 변경 가능
          } else {
            clearInterval(interval);
            return target;
          }
        });
      }, 100); // 숫자가 증가하는 속도를 조절하고 싶다면 이 값을 조절
    };

    // 스크롤 이벤트 핸들러: 지정된 요소들이 화면에 하단-> 상단 애니메이션 효과 적용
    const handleScroll = () => {
      const elementsToAnimate = document.querySelectorAll(
        '.home-difference-title,.home-difference-subtitle,.home-differenceIMG,.home-difference-body,.homeRoutine,.home-left-col,.초보골퍼, .중고수골퍼, .도심속인도어, .연습레슨, .외곽인도어, .설치이슈, .home-contact'
      );
      const windowHeight = window.innerHeight;

      // 날짜 차이를 이용하여 3240에 18씩 추가되는 값을 계산
      const daysDiff = calculateDaysDifference();
      const updatedValue = 100 + daysDiff * 18;
      const userCount = 150 + daysDiff * 85;

      elementsToAnimate.forEach((element) => {
        //handleCountUp 함수를 여기서 호출해야 component가 화면에 보일 때 함수가 시작된다.
        if (element.getBoundingClientRect().top < windowHeight - 200) {
          element.classList.add('visible');
          handleCountUp(0, setContractCount, 2);
          handleCountUp(0, setUserCount, userCount);
          handleCountUp(0, setRecordingHours, updatedValue);
          handleCountUp(0, setSalesCount, 16);
        }
      });
    };
    // 화면 너비 조절
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      if (window.innerWidth >= 1024) {
        // 여기에서 너비 조건 설정
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll); // 스크롤 이벤트에 함수 연결
    handleScroll();

    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(handleCountUp);
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <div className="outer" style={{ backgroundColor: 'black' }}>
      <div className="home-content">
        <Header />
        <ScrollToTopButton />
        <Wrap style={{ width: '100%' }}>
          <div className="home-bg">
            <div className="home-blur-background"></div>
            <div className="homeBg-content" style={{ marginTop: `${windowHeight / 12}px` }}>
              <div className="e8_60-container">
                <div className="home-bg-topText">
                  ShoTrack과 함께 <br />
                  최상의 스윙을 완성하세요!
                </div>
              </div>
            </div>
          </div>
        </Wrap>
        {/* Second Box */}
        <div className="home-secondBox">
          <div className="home-left-col">
            <div className="home-secondBoxLogo"></div>
            <p className="home-secondBoxMent">
              프로들이 사용하는 정확도 높은 장비.
              <br /> 스윙, 궤적 분석 및 코칭으로 나만의 루틴을 만들어보세요.
              <br /> ShoTrack과 함께라면 스윙의 질이 달라질 거에요!
            </p>
            <Link to="/products" className="home-secondBoxLink">
              자세히보기
            </Link>
          </div>
          <div className="home-secondBox-right-col">
            <div className="home-secondBox-number-box">
              <h2>
                {contractCount}
                <span className="home-secondBox-unit">개</span>
              </h2>
              <p>누적 계약업체</p>
            </div>
            <div className="home-secondBox-number-box">
              <h2>
                {userCount}
                <span className="home-secondBox-unit">명</span>
              </h2>
              <p>누적 이용자수</p>
            </div>
            <div className="home-secondBox-number-box">
              <h2>
                {recordingHours}
                <span className="home-secondBox-unit">시간</span>
              </h2>
              <p>누적 촬영 시간</p>
            </div>
            <div className="home-secondBox-number-box">
              <h2>
                {salesCount}
                <span className="home-secondBox-unit">개</span>
              </h2>
              <p>누적 판매수</p>
            </div>
          </div>
        </div>
        {/* Routine Box */}
        <div className="routineSection" style={{ width: windowWidth }}>
          <div className="routineTitle">LABINNO</div>
          <div className="routineContent">
            {windowWidth > 920 ? (
              <div>ShoTrack의 정밀한 분석시스템으로 스윙 정확도를 높일 수 있습니다.</div>
            ) : windowWidth > 600 ? (
              <div>
                ShoTrack의 정밀한 분석시스템으로 <br />
                스윙 정확도를 높일 수 있습니다.
              </div>
            ) : (
              <div>
                ShoTrack의 <br />
                정밀한 분석시스템으로 <br />
                스윙 정확도를 높일 수 있습니다.
              </div>
            )}
          </div>

          <div className="homeRoutine">
            <div className="routine-card">
              <div className="routine-card-content">
                <h3>ShoTrack 실행 후 스윙</h3>
                <div className="home-routine-divider"></div>
                <p>ShoTrack을 실행하고 편하게 스윙하세요.</p>
              </div>
            </div>
            <div className="routine-card">
              <div className="routine-card-content">
                <h3>스윙 자세 향상</h3>
                <div className="home-routine-divider"></div>
                <p>
                  ShoTrack의 실시간 계측, 스윙 분석 기능으로 <br />
                  올바른 스윙 자세로 연습하세요!
                </p>
              </div>
            </div>
            <div className="routine-card">
              <div className="routine-card-content">
                <h3>퍼포먼스 향상</h3>
                <div className="home-routine-divider"></div>
                <p>
                  올바른 자세, 반복적인 연습으로
                  <br />
                  최상의 스윙을 만들어보세요!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Difference Box */}
        <div className="grid-container">
          {/* Row 1 */}
          <div className="grid-item photo-left">
            <div className="home-differenceIMG" id="HD1"></div>
          </div>
          <div className="grid-item text-right">
            <h2 className="home-difference-title">실시간 다중 궤적 추적</h2>
            <h3 className="home-difference-subtitle">여러 타석의 공을 실시간으로 추적</h3>
            <p className="home-difference-body">
              각 타석마다 설치해야 하는 론치 모니터와 달리, <br />단 2대의 카메라만으로 20~30타석 동시 관측 가능!
            </p>
          </div>

          {/* Row 2 */}
          <div className="grid-item text-left">
            <h2 className="home-difference-title">딥러닝 기반의 궤적 예측</h2>
            <h3 className="home-difference-subtitle">프로가 사용하는 정확도 높은 기술</h3>
            <p className="home-difference-body">
              0.5m 이내 정보만으로 예측하는 론치 모니터와 달리, <br />
              10~100m 범위의 실측 기반 정보로 궤적 정보 도출
            </p>
          </div>
          <div className="grid-item photo-right">
            <div className="home-differenceIMG" id="HD2"></div>
          </div>

          {/* Row 3 */}
          <div className="grid-item photo-left">
            <div className="home-differenceIMG" id="HD3"></div>
          </div>
          <div className="grid-item text-right">
            <h2 className="home-difference-title">직관적인 공의 궤적</h2>
            <h3 className="home-difference-subtitle">내가 친 공의 궤적을 쉽게 확인할 수 있어요.</h3>
            <p className="home-difference-body">
              내 공이 어딨지? 걱정 말고 스윙하세요.
              <br /> ShoTrack에서 실시간으로 공의 궤적을 보여드립니다!
            </p>
          </div>
          {/* Row 4 */}
          <div className="grid-item text-left">
            <h2 className="home-difference-title">설치 장비 최소화</h2>
            <h3 className="home-difference-subtitle">설치, 수리할 때마다 영업 불가능한 거 아닌가요?</h3>
            <p className="home-difference-body">
              ShoTrack의 강점 <br />
              설치 장비 및 과정을 모두 최소화! <br />
              영업 중인 매장에서도 설치, 수리가 가능합니다.
            </p>
          </div>
          <div className="grid-item photo-right">
            <div className="home-differenceIMG" id="HD4"></div>
          </div>

          {/* Row 5 */}
          <div className="grid-item photo-left">
            <div className="home-differenceIMG" id="HD5"></div>
          </div>
          <div className="grid-item text-right">
            <h2 className="home-difference-title">간단한 기기 작동법</h2>
            <h3 className="home-difference-subtitle">기계에 익숙하지 못 한 분들은 사용 불가능한 거 아닌가요?</h3>
            <p className="home-difference-body">
              깔끔하고 직관적인 디자인으로 복잡한 설정 없이, 누구나 간단하게 사용할 수 있습니다.
            </p>
          </div>

          {/* Row 6 */}
          <div className="grid-item text-left">
            <h2 className="home-difference-title">다양한 단말기</h2>
            <h3 className="home-difference-subtitle">궤적 정보를 핸드폰에서도 확인해보세요!</h3>
            <p className="home-difference-body">
              키오스크, 태블릿 심지어 개인 스마트폰에서도 궤적 정보를 받아볼 수 있습니다.
            </p>
          </div>
          <div className="grid-item photo-right">
            <div className="home-differenceIMG" id="HD6"></div>
          </div>
        </div>

        <div className="home-helpful-tech">
          <div className="home-tech-title">
            프로, 아마추어, 골프장 모두에게 도움이 될 기술,
            <br /> ShoTrack으로 모든 고민을 해결하세요!
          </div>
          <Link className="home-Link" to="/contact">
            제품문의하러가기 →
          </Link>

          {/* helpful Box */}
          <div className="helpful-col">
            {/* Row 1 */}
            <div className="golfers-row">
              <div className="초보골퍼">
                <div className="golfers-rowImg" id="golfer1"></div>
                <div className="maintxt">초보골퍼</div>
                <div className="subtxt">
                  내 공은 어디에? <br /> 자신의 공을 보기 어려움
                </div>
              </div>
              <div className="중고수골퍼">
                <div className="golfers-rowImg" id="golfer2"></div>
                <div className="maintxt">중/고수 골퍼</div>
                <div className="subtxt">
                  측정할 수 없는 것은 개선할 수 없다! <br /> 클럽별 정확한 캐리 거리 측정 필요
                </div>
              </div>

              <div className="연습레슨">
                <div className="golfers-rowImg" id="golfer3"></div>
                <div className="maintxt">연습/레슨</div>
                <div className="subtxt">
                  스윙 모습을 보려면 매번 핸드폰으로 <br /> 영상을 찍어야 하는 불편함
                </div>
              </div>
            </div>
            {/* Row 2 */}
            <div className="ceo-row">
              <div className="도심속인도어">
                <div className="ceo-rowImg" id="ceo1"></div>
                <div className="maintxt">도심 속 인도어</div>
                <div className="subtxt">
                  짧은 전장, 전체 궤적 관측 어려움 <br /> 신뢰성 있는 궤적 데이터 필요
                </div>
              </div>
              <div className="외곽인도어">
                <div className="ceo-rowImg" id="ceo2"></div>
                <div className="maintxt">외곽 인도어</div>
                <div className="subtxt">
                  접근성 낮아 경쟁력 필수, 멀리 관측 가능 <br /> 정확한 궤적 데이터 필요
                </div>
              </div>
              <div className="설치이슈">
                <div className="ceo-rowImg" id="ceo3"></div>
                <div className="maintxt">설치/관리/고장 이슈</div>
                <div className="subtxt">
                  론치모니터는 타석마다 설치 <br /> 장비 고가 & 시공 과정 복잡 <br /> 고장 이슈 빈번
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Box */}
        <Inquery />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
