import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/aboutstyle.css';
import '../styles/entire.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Wrap from '../Functions/Wrapper';
import profile1 from '../images/최정식.png'; // Example image import, replace with actual paths
import profile2 from '../images/김회율.png';
import profile3 from '../images/김회춘.png';
import profile4 from '../images/하태희.png';
import profile5 from '../images/이광진.png';
import profile6 from '../images/김기태.png';
import profile7 from '../images/오현석.png';
import profile8 from '../images/곽주리2.png';
import img1 from '../images/about-img1.png';
import img2 from '../images/about-img2.png';
import img3 from '../images/about-img3.png';
import img4 from '../images/about-img4.png';
import ScrollToTopButton from '../Functions/ScrollToTopButton';
const About = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -450, // 스크롤하는 픽셀 수 (조정 가능)
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 450, // 스크롤하는 픽셀 수 (조정 가능)
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // iOS와 MacOS 사용자에게 특정 클래스 추가 (ios 환경에는 다른 css 적용을 위해)
    const userAgent = navigator.userAgent;
    const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isMacOS = /Macintosh|MacIntel|Mac OS X/.test(userAgent);

    if (isiOS) {
      document.documentElement.classList.add('ios');
    }
    if (isMacOS) {
      document.documentElement.classList.add('mac');
    }
    const handleScroll = () => {
      const elementsToAnimate = document.querySelectorAll(
        '.main-title, .photo-item,.description,.timeline-container,.years, .Nyears, .years-text,.TeamContainer,.team, .tech-section,.tech-title,.news-title,.news-section'
      );
      const windowHeight = window.innerHeight;

      elementsToAnimate.forEach((element) => {
        if (element.getBoundingClientRect().top < windowHeight - 200) {
          element.classList.add('visible');
        }
      });
    };
    // 화면 너비 조절
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      if (window.innerWidth >= 1024) {
        // 여기에서 너비 조건 설정
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  //BG에 위치한 하단 화살표를 위한 함수. 클릭 시 화면 높이만큼 스크롤
  const handleArrowClick = () => {
    window.scrollBy({
      top: window.innerHeight, // 화면 높이만큼 스크롤
      behavior: 'smooth', // 스크롤 애니메이션
    });
  };
  return (
    <div className="outer">
      <div className="about-content">
        <Header />
        <ScrollToTopButton />
        <div className="head-image-container">
          <div className="header-img"></div>
          <div className="about-bgcontent" style={{ marginTop: `${windowHeight / 12}px` }}>
            <Wrap style={{ width: '100%' }}>
              <div className="about-bgbox">
                <div className="about-bgtext">
                  {windowWidth > 520 ? (
                    <span>
                      Lab<span className="color">inno</span>, <span className="color">혁신</span>을 만들어내다.
                    </span>
                  ) : (
                    <>
                      <span>
                        Lab<span className="color">inno</span>, <br />
                        <span className="color">혁신</span>을 만들어내다.
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Wrap>
          </div>
          <div className="scroll-arrow" onClick={handleArrowClick}>
            &#9660;
          </div>
        </div>
        {/* Second Box */}
        <div className="description">
          {windowWidth > 500 ? (
            <span>
              <span className="labbino" style={{ color: '#15691d' }}>
                래비노(Labinno)
              </span>
              는 초고속 스테레오 비전 시스템을 활용한 <br />
              물체의 궤적 추적 시스템을 개발하고 있습니다. <br />
              해외기술 의존적인 궤적 추적 시스템에서 벗어나 <br />
              독자적인 기술로 차별성과 경쟁력을 확보했으며, <br />
              AI 분석 기술의 도입으로 스포츠 테크 분야를 선도하고자 합니다.
            </span>
          ) : (
            <>
              <span style={{ marginTop: `${windowHeight / 2}px` }}>
                <di className="labbino" style={{ color: '#15691d' }}>
                  래비노(Labinno)
                  <br />
                </di>
                는 초고속 스테레오 비전 시스템을 활용한 <br />
                물체의 궤적 추적 시스템을 개발하고 있습니다. <br />
                해외기술 의존적인 궤적 추적 시스템에서 벗어나
                <br /> 독자적인 기술로 차별성과 경쟁력을 확보했으며, <br />
                AI 분석 기술의 도입으로 스포츠 테크 분야를 <br />
                선도하고자 합니다.
              </span>
            </>
          )}
        </div>

        <div className="photo-grid">
          <div className="photo-item">
            <img src={img1} alt="img1" />
          </div>
          <div className="photo-item">
            <img src={img2} alt="img2" />
          </div>
          <div className="photo-item">
            <img src={img3} alt="img3" />
          </div>
          <div className="photo-item">
            <img src={img4} alt="img4" />
          </div>
        </div>

        {/* Timeline */}
        <div className="timeline" style={{ marginTop: `${windowHeight / 2}px` }}>
          <section className="timeline-container">
            <h6 className="timeline-title">
              Labinno는 세계 최고를 위해 <br />
              어제도, 오늘도 미래를 그리고 있습니다.
            </h6>
            <div className="arrow"></div>
            <div className="years">
              <div className="Nyears">2026</div>
              <div className="Nyears">2025</div>

              <div className="Nyears">2024.6</div>
              <div className="years-text">코오롱 스포렉스 용답 20타석 설치 계약</div>
              <div className="years-text">왕십리 위너스파크 돔골프 6타석 시범 설치 확정</div>
              <div className="years-text">
                레드골프 세일즈 파트너십 체결
                <div className="레드골프사진"></div>
              </div>
              <div className="years-text">
                줌테크 (국내 티업기 1위) 세일즈 파트너십 체결
                <div className="줌테크사진"></div>
              </div>
              <div className="years-text">
                코아소프트와 ShoTrack 엔진 일본 공급 MOU 체결
                <div className="코아소프트사진"></div>
              </div>
              <div className="years-text">일본 미사토 골프센터 ShotTrack 엔진 2세트 설치 확정</div>
              <div className="Nyears">2024.5</div>
              <div className="years-text">스포렉스 용답 시제품 2타석 설치</div>
              <div className="Nyears">2024.3</div>
              <div className="years-text">스포렉스 용답 2세트 설치 및 시험 운용</div>
              <div className="Nyears">2023.7</div>
              <div className="years-text">ShoTrack 엔진 베타 테스트 시작</div>
              <div className="Nyears">2022.6</div>
              <div className="years-text">골프로 피벗</div>
            </div>
          </section>
        </div>
        {/* Team */}
        <div className="TeamContainer" style={{ marginTop: `${windowHeight / 20}px` }}>
          Labinno's Team
        </div>
        <div className="Team">
          {/* Row  1 */}
          <div className="bottom-row">
            <div className="team">
              <img src={profile1} alt="최정식" className="profile-photo" />
              <div className="teamName">최정식</div>
              <div className="work">CEO</div>
              <div className="about-divider"></div>
              <div className="career">
                반디소프트 개발팀장 <br />
                삼성전자 선임연구원 <br />
                한양대학교 대학원 석사 (영상공학전공)
              </div>
            </div>
            <div className="team">
              <img src={profile2} alt="김회율" className="profile-photo" />
              <div className="teamName">김회율</div>
              <div className="work">CPO</div>
              <div className="about-divider"></div>
              <div className="career">
                {' '}
                한양대학교 융합전자공학부 교수 <br /> 미국 퍼듀대학교
                <br /> (전자공학 박사)
              </div>
            </div>
            <div className="team">
              <img src={profile4} alt="하태희" className="profile-photo" />
              <div className="teamName">하태희</div>
              <div className="work">CTO</div>
              <div className="about-divider"></div>
              <div className="career">
                한양대학교 대학원 박사과정
                <br /> (융합전자공학과)
              </div>
            </div>
            <div className="team">
              <img src={profile3} alt="김회춘" className="profile-photo" />
              <div className="teamName">김회춘</div>
              <div className="work">CFO</div>
              <div className="about-divider"></div>
              <div className="career">
                숭실대학교 일반대학원 (융합공학 박사) <br />
                (주)브이알스토리 대표이사 <br />
                (주)인브레인인스티튜트 부사장
              </div>
            </div>
          </div>
          {/* Row  2 */}
          <div className="bottom-row">
            <div className="team">
              <img src={profile5} alt="이광진" className="profile-photo" />
              <div className="teamName">이광진</div>
              <div className="work">개발본부장</div>
              <div className="about-divider" style={{ height: '2px' }}></div>
              <div className="career">
                (주)톡솔루션 대표 <br />
                (주)SK컴즈 네이트온 개발팀장 <br />
                한양대학교 대학원 석사 (영상공학전공)
              </div>
            </div>
            <div className="team">
              <img src={profile6} alt="김기태" className="profile-photo" />
              <div className="teamName">김기태</div>
              <div className="work">선임연구원</div>
              <div className="about-divider"></div>
              <div className="career">한양대학교 대학원 석사 (융합전자공학과)</div>
            </div>
            <div className="team">
              <img src={profile7} alt="오현석" className="profile-photo" />
              <div className="teamName">오현석</div>
              <div className="work">주임연구원</div>
              <div className="about-divider"></div>
              <div className="career">한양대학교 융합전자공학부생</div>
            </div>
            <div className="team">
              <img src={profile8} alt=" 곽주리" className="profile-photo" />
              <div className="teamName">곽주리</div>
              <div className="work">인턴</div>
              <div className="about-divider"></div>
              <div className="career">한양대학교 컴퓨터소프트웨어학부생</div>
            </div>
          </div>
        </div>
        {/* News */}
        <h1 className="news-title">OUR NEWS</h1>
        <section className="news-section">
          <button onClick={scrollLeft} className="scroll-button left">
            ◀
          </button>
          <div className="news-container" ref={containerRef}>
            <a href="https://platum.kr/archives/209795" className="news-item" target="_blank" rel="noreferrer">
              <div className="news-image image-1"></div>
              <span className="news-text">
                씨엔티테크, 2023 스포츠산업 창업도약센터 참여기업 최종 선발 및 협약 완료
              </span>
            </a>
            <a href="https://www.etnews.com/20230414000130" className="news-item" target="_blank" rel="noreferrer">
              <div className="news-image image-2"></div>
              <span className="news-text">[전화성의 기술창업 Targeting] '스포츠산업 창업도약센터' 지원사업</span>
            </a>
            <a href="https://video.venturesquare.net/archives/video/14304" className="news-item" target="_blank" rel="noreferrer">
              <div className="news-image image-3"></div>
              <span className="news-text">
                래비노, 광학식 다중 골프공 추적기능을 이용한 실외연습용 올인원 시뮬레이터
              </span>
            </a>
            <a href="https://www.etnews.com/20240428000069" className="news-item" target="_blank" rel="noreferrer">
              <div className="news-image image-4"></div>
              <span className="news-text">경기창경센터, 판교 창업존서 제20회 스타트업 815 IR-연합 성료</span>
            </a>
          </div>
          <button onClick={scrollRight} className="scroll-button right">
            ▶
          </button>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default About;
