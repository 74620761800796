// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import ProductPage from './pages/Products';
import TechPage from './pages/Tech';
import ScrollToTop from './Functions/ScrollTop';

function App() {
  return (
    <Router>
      {/*페이지 전환 시에도 늘 최상단 위치 */}
      <ScrollToTop />
      <Routes>
        {/*기본 url 뒤에 /가 붙으면 Home 컴포넌트를 랜더링하겠다*/}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products" element={<ProductPage />} />
        <Route path="/technology" element={<TechPage />} />
      </Routes>
    </Router>
  );
}

export default App;
