import React from 'react';
import '../styles/techstyle.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
const TechPage = () => {
  const scrollToFeature = (feature) => {
    const element = document.getElementById(feature);
    const offset = -500; // Adjust this value to set your desired offset
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div className="wrapper">
      <div className="content">
        <Header />

        <div className="TechImage"></div>
        <div className="TechDescription">
          <div className="TechTitle">
            <div className="Labinno">
              <span className="blackText bold">Lab</span>
              <span className="blueText bold">inno</span>
            </div>
            <div className="techtitle_message">
              <span className="blackText">스포츠 퍼포먼스를 향상시키는 </span>
              <span className="blueText">혁신적인</span>
              <span className="blackText"> 딥러닝 기술</span>
            </div>
          </div>
          <div className="TechFeatures">
            <div className="FeatureItem" onClick={() => scrollToFeature('실시간 다중 궤적 추적')}>
              실시간 다중 궤적 추적
            </div>
            <div className="FeatureItem" onClick={() => scrollToFeature('딥러닝 기반의 궤적 예측')}>
              딥러닝 기반의 궤적 예측
            </div>
            <div className="FeatureItem" onClick={() => scrollToFeature('AI 기반 스트라이트 판정')}>
              AI 기반 스트라이트 판정
            </div>
            <div className="FeatureItem" onClick={() => scrollToFeature('특허 및 논문')}>
              보유 특허 및 논문
            </div>
          </div>
        </div>
        <div className="FeatureDetail" id="실시간 다중 궤적 추적">
          <div className="golfball"></div>
          <div className="FeatureTitle">실시간 다중 궤적 추적</div>
          <div className="FeatureDescription">
            동시에 최대 20 타석의 공을 추적 가능한 기술
            <br />
            +보충 설명
          </div>
        </div>
        <div className="FeatureDetail" id="딥러닝 기반의 궤적 예측">
          <div className="creavity"></div>
          <div className="FeatureTitle">딥러닝 기반의 궤적 예측</div>
          <div className="FeatureDescription">
            10~50m 내 공의 궤적을 기반으로 <br />
            딥러닝 기술을 통해 약 OO%의 정확도로
            <br />
            공의 전체 궤적 예측
            <br />
            +보충 설명
          </div>
        </div>
        <div className="FeatureDetail" id="AI 기반 스트라이트 판정">
          <div className="FeatureTitle">AI 기반 스트라이크 판정</div>
          <div className="tech_robot"></div>
          <div className="FeatureDescription">
            타석에 들어서는 타자의 신체를 기반으로 <br />
            스트라이크 존 설정 후 AI 기술로 스트라이크 여부 판단
            <br />
            +부가 설명
          </div>
        </div>
        <div className="accordionTitle" id="특허 및 논문">
          보유 특허 & 논문
        </div>
        <div className="accordion">
          <div className="tech-card" id="tech-card1">
            {' '}
            <div className="tech-mainText">특허</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle1">
              운동 궤적 분석을 이용한 훈련 방법 및 장치
            </div>{' '}
            <div className="tech-description">한양대학교 (김회율) / 대한민국(10-2014-0161896)</div>
            <div className="tech-circle" id="tech-circle1"></div>
          </div>
          <div className="tech-card" id="tech-card2">
            {' '}
            <div className="tech-mainText">특허</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle2">
              투구 연습을 통한 3차원 정보 획득 시스템 및 카메라 파라미터 산출 방법
            </div>{' '}
            <div className="tech-description">한양대학교 (김회율) / 대한민국(10-2016-0095235)</div>
            <div className="tech-circle" id="tech-circle2"></div>
          </div>
          <div className="tech-card" id="tech-card3">
            {' '}
            <div className="tech-mainText">특허</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle3">
              동적 오브젝트에 대한 3차원 정보 디스플레이 시스템
            </div>{' '}
            <div className="tech-description">한양대학교 (김중식,김회율) / 대한민국(10-2017-0119817)</div>
            <div className="tech-circle" id="tech-circle3"></div>
          </div>
          <div className="tech-card" id="tech-card4">
            {' '}
            <div className="tech-mainText">특허</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle4">
              투구 분석 시스템 및 그 방법
            </div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle14"></div>{' '}
            <div className="tech-description">한양대학교 (이홍준,김회율,김제연) / 대한민국(10-2017-0151827)</div>
            <div className="tech-circle" id="tech-circle4"></div>
          </div>
          <div className="tech-card" id="tech-card5">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle5">
              초고속 카메라로 촬영한 투구 영상에서의 야구공 위치 검출 방법
            </div>{' '}
            <div className="tech-description">한양대학교 (김중식 외 2인) / (국내) 2017 제29회 IPIU</div>
            <div className="tech-circle" id="tech-circle5"></div>
          </div>
          <div className="tech-card" id="tech-card6">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle6">
              3차원 투구 궤적 분석 시스템의 성능평가를 위한 야구공 위치 측정 방법
            </div>{' '}
            <div className="tech-description">한양대학교 (김제연 외 3인) / (국내) 2018 제30회 IPIU</div>
            <div className="tech-circle" id="tech-circle6"></div>
          </div>
          <div className="tech-card" id="tech-card7">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle7">
              자동화된 초고속 카메라 기반 야구 투구 위치 분석 시스템
            </div>{' '}
            <div className="tech-description">한양대학교 (조은지 외 4인) / (국내) 2020 제 32회 IPIU</div>
            <div className="tech-circle" id="tech-circle7"></div>
          </div>
          <div className="tech-card" id="tech-card8">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle8">
              테니스 경기 중 라인 인/아웃 판정을 위한 스테레오 카메라 기반의 3차원 테니스 궤적 분석 방법
            </div>{' '}
            <div className="tech-description">한양대학교 (김중식 외 5인) / (국내) 2020 제 32회 IPIU</div>
            <div className="tech-circle" id="tech-circle8"></div>
          </div>
          <div className="tech-card" id="tech-card9">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle9">
              REAL CATCHER VIEW IMAGE GENERATION METHOD FOR BASEBALL CONTENTS
            </div>{' '}
            <div className="tech-description">한양대학교 (김제연 외 4인) / (해외) 2018 IWAIT</div>
            <div className="tech-circle" id="tech-circle9"></div>
          </div>
          <div className="tech-card" id="tech-card10">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle10">
              BASEBALL DETECTION METHOD ROBUST TO OCCLUSION IN HITTING AREA BASED ON HIGH SPEED CAMERA
            </div>{' '}
            <div className="tech-description">한양대학교 (김중식 외 1인) / (해외) IW-FCV 2018</div>
            <div className="tech-circle" id="tech-circle10"></div>
          </div>
          <div className="tech-card" id="tech-card11">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle11">
              START-END TIME DETECTION FOR AUTOMATIC BASEBALL PITCHING TRAJECTORY ANALYSIS
            </div>{' '}
            <div className="tech-description">한양대학교 (이홍준 외 4인) / (해외) ICEIC 2019</div>
            <div className="tech-circle" id="tech-circle11"></div>
          </div>
          <div className="tech-card" id="tech-card12">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle12">
              FULLY AUTOMATIC PITCHING LOCATION ANALYSIS SYSTEM USING HIGH-SPEED CAMERAS
            </div>{' '}
            <div className="tech-description">한양대학교 (조은지 외 4인) / (해외) ICEIC 2020</div>
            <div className="tech-circle" id="tech-circle12"></div>
          </div>
          <div className="tech-card" id="tech-card13">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle13">
              A METHOD OF MEASURING BASEBALL POSITION AT THE STRIKE ZONE
            </div>{' '}
            <div className="tech-description">한양대학교 (이홍준 외 3인) / (해외) ICEIC 2020</div>
            <div className="tech-circle" id="tech-circle13"></div>
          </div>
          <div className="tech-card" id="tech-card14">
            {' '}
            <div className="tech-mainText">논문</div>{' '}
            <div className="tech-descriptionTitle" id="tech-descriptionTitle14">
              PRECISE 3D BASEBALL PITCHING TRAJECTORY ESTIMATION USING MULTIPLE UNSYNCHRONIZED CAMERAS
            </div>{' '}
            <div className="tech-description">한양대학교 (김중식 외 4인) / (SCIE 저널) IEEE Access, Vol. 7</div>
            <div className="tech-circle" id="tech-circle14"></div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TechPage;
