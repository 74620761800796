import React, { useState, useEffect } from 'react';
import '../styles/ScrollToTopButton.css';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  //스크롤 위치에 따라서 버튼을 보이게 하거나 숨기는 함수
  //스크롤 위치가 300px 이상일 경우에만 버튼을 보이게 하였음
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // 최상단으로 이동
    });
  };
  // 컴포넌트 마운트, 언마운트 시 각각 스크롤 이벤트 리스너 등록, 해제
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    //버튼 클릭 시 최상단 이동 스크롤 위치 기반으로 표시하거나 숨김
    <button
      onClick={scrollToTop}
      className="scroll-to-top"
      style={{
        display: isVisible ? 'flex' : 'none',
      }}
    >
      &#8593;
    </button>
  );
};

export default ScrollToTopButton;
