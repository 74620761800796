import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/inquerystyle.css';
const Inquery = () => {
  return (
    <div className="inquery">
      <div className="inquery-content">
        <span className="inquery-title">ShoTrack</span>
        <span className="inquery-body">
          인도어 연습장의 혁신을 선도하는 ShoTrack <br />
          구입, 렌탈, 가격 등 자세한 내용 문의하기
        </span>
        <Link className="inquery-link" to="/contact">
          Contact Labinno
        </Link>
      </div>
    </div>
  );
};

export default Inquery;
