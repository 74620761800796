// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  // 현재 경로(location)의 정보를 사용하기 위해 useLocation 훅 사용
  const { pathname } = useLocation();
  //의존성 배열을 pathname으로 해서 경로가 변경될 때마다 스크롤을 최상단으로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // 컴포넌트가 시각적 출력을 하지 않고, 특정 기능만 수행하므로 null 반환
  return null;
};

export default ScrollToTop;
