import React from 'react';
import { motion } from 'framer-motion';
import { pageEffect } from '../styles/animation';

//보다 느린 전환을 위해 사용 (home BG, about BG)
const Wrap = ({ children, ...rest }) => {
  return (
    <motion.div
      initial="initial"
      animate="in" // 애니메이션 활성화 상태 설정
      exit="out" // 컴포넌트가 사라질 때 적용할 상태 설정
      transition={{ duration: 1.7 }} // 애니메이션 전환 속도 설정
      variants={pageEffect} // 애니메이션 변형 설정 (pageEffect 객체에서 정의)
      {...rest} // 나머지 props를 motion.div에 전달
    >
      {children}
    </motion.div>
  );
};

export default Wrap;
